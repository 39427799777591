import React from 'react'
import eduardo from '../../../assets/images/story/factoryfix/eduardo.png'
import image2 from '../../../assets/images/story/factoryfix/factoryfix-daily-digest.png'
import image1 from '../../../assets/images/story/factoryfix/factoryfix-org-insights.png'
import image4 from '../../../assets/images/story/factoryfix/factoryfix-prs.png'
import image3 from '../../../assets/images/story/factoryfix/factoryfix-work-log.png'
import tim from '../../../assets/images/story/factoryfix/tim.png'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'
import QuoteBlock from '../QuoteBlock'

const FactoryFixBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <>
            <p>
              FactoryFix is the leading manufacturing recruitment platform.
              Founded in 2017, the company serves hundreds of manufacturing
              businesses and has over 1,000,000 industry professionals in its
              talent network.
            </p>
            <a
              target="\_blank"
              rel="noopener noreferrer"
              href="https://factoryfix.com/"
            >
              https://factoryfix.com/
            </a>
          </>
        ),
        location: <p>Headquartered in Chicago, fully remote</p>,
        headcount: <p>40+ employees, 10+ engineers</p>,
        customers: <p>2022</p>,
      }}
      main={
        <>
          <p>
            Tim Nott, CTO of FactoryFix, leads a fully remote organization of
            10+ software engineers split into three feature teams that are
            building the leading SaaS product for manufacturing recruitment.
            Together, Tim and Engineering Manager Eduardo Bilk started looking
            for a tool that would help them align engineering with the business
            and gain visibility into the process bottlenecks that were getting
            in the way of their teams.
          </p>
          <p>
            Tim’s main goal was to find a solution that would help communicate
            engineering progress and priorities to the non-engineering
            leadership. He wanted to identify a set of key metrics that would
            help the engineering organization not only measure but also improve
            its delivery time.
          </p>
          <QuoteBlock
            quote="We’re fighting the age-old battle of trying to align development with how the rest of the business talks about itself. The problem is, it’s hard to find good metrics. We want to get to a point where we can confidently talk to the CEO and Head of Sales about the metrics we’re tracking and demonstrate how we’re using them to get features out faster."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />
          <p>
            FactoryFix is known for its culture of high ownership and
            transparency. As the engineering leader, Tim was looking to bring
            the same degree of transparency into engineering that the other
            departments were already able to offer.
          </p>
          <QuoteBlock
            quote="All our sales, customer success, and marketing metrics are displayed and shared with everyone in the company. Dev is the hardest thing to give transparency to, and so we tried using Shortcut as our transparency tool. The problem is, it doesn’t allow us to see in real time when things are starting to slip. I want to be able to spot and communicate that instantly, as opposed to having to wait until we’ve already missed a deadline."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />
          <p>
            Instead of having to wait for a post-mortem to analyze what went
            wrong, Tim and Eduardo were hoping to become more proactive about
            catching blockers and communicating about unexpected delays to
            internal stakeholders.
          </p>
          <QuoteBlock
            quote="Once upon a time I was able to look at every single project and see where the chokeholds were. Now, finding those pain points has gotten increasingly more complicated. I want to have a tool that helps me work with dev leads and say like ‘hey, whatever we’re doing over here is not working, let’s figure out a way to move this along."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />

          <h2>Improving transparency, predictability, and delivery</h2>

          <p>
            Around the time Tim and Eduardo started looking into engineering
            productivity tools, they were also working on switching from
            Shortcut to Linear and adopting{' '}
            <a href="https://basecamp.com/shapeup">Shape Up</a> as their
            software development methodology. They started off by comparing
            Swarmia, LinearB, and GitClear, but quickly chose Swarmia as the
            most suitable tool for achieving the outcomes they were after.
          </p>
          <p>
            Early on in the evaluation process, Tim and Eduardo were impressed
            by the visibility Swarmia was able to provide to not only the
            engineering teams but also the rest of the business.
          </p>
          <QuoteBlock
            quote="Instantly, Swarmia gave us a baseline, and we could see historical information on where we were in terms of metrics. We wanted to make those metrics available to the teams so they could reflect on their own work but also to the executive team to help them better understand bottlenecks in the delivery process."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />
          <ProductScreenshot src={image1} />
          <p>
            The insights in Swarmia also turned out to be useful in supporting
            the conversations Tim was having in the leadership team.
          </p>
          <QuoteBlock
            quote="One of our hypotheses is that there are things we can do in our process to make delivery better. The other hypothesis is that some of the expectations from the business may be unrealistic and that we might need more resources or more senior resources to reach our goals. Swarmia makes those conversations easier and allows us to pull the right levers at the right time."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />
          <p>
            Eduardo, on the other hand, quickly got into the habit of using
            Swarmia’s Daily Digests in Slack to understand work in progress and
            unblock team members who needed unblocking. The scheduled recaps in
            Slack help Eduardo stay on top of the work his team is doing.
          </p>
          <QuoteBlock
            quote="The Daily Digest is our favorite — not having to leave Slack to see what you have to do is awesome."
            name="Eduardo Bilk"
            title="Engineering Manager"
            photo={eduardo}
          />
          <ProductScreenshot src={image2} />
          <p>
            During the trial period, Eduardo spent some time on comparing
            LinearB’s and Swarmia’s Slack notifications to one another. He found
            that LinearB’s notifications felt punitive and complicated, whereas
            the ones in Swarmia were more straightforward and actionable.
          </p>
          <p>
            In addition to interacting with Swarmia’s Slack app, Eduardo also
            likes to look at the Work Log to get a quick visualization of how
            the work is moving forward.
          </p>
          <QuoteBlock
            quote="Swarmia has become a part of my day-to-day work. I log in to Swarmia and I see how the teams are doing and I look at the Work Log to see what they’re working on."
            name="Eduardo Bilk"
            title="Engineering Manager"
            photo={eduardo}
          />
          <ProductScreenshot src={image3} />

          <h2>
            Immediate ROI with 25% drop in cycle time and replacing daily
            stand-ups with Swarmia’s Daily Digests
          </h2>
          <p>
            Since FactoryFix’s Swarmia adoption coincided with their
            implementation of the Shape Up methodology, they’ve been able to
            prove the new methodology’s impact on the speed of delivery. After
            the first 90 days of using Swarmia and Shape Up, the FactoryFix
            engineering teams saw an average of 25% drop in their pull request
            cycle times and a 9% increase in their review rate.
          </p>
          <QuoteBlock
            quote="Our goal with Shape Up is to deliver as fast as we can. Ever since we adopted it, we’ve definitely felt the review rate improving because we used to only have a few people reviewing code and now code reviews are more distributed. We can clearly see these improvements in Swarmia."
            name="Eduardo Bilk"
            title="Engineering Manager"
            photo={eduardo}
          />
          <ProductScreenshot src={image4} />
          <p>
            Additionally, with Swarmia’s Daily Digests in every team’s Slack
            channel, each engineering team has been able to skip their daily
            stand-ups and reduce the number of recurring meetings.
          </p>
          <QuoteBlock
            quote="We saw an immediate return on investment. While the product has many great features, the team-by-team daily recap in Slack eliminates the need for stand-ups. Now we do meetings on an as-needed basis if it looks like we might not deliver on time."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />
          <p>
            During the next 12-24 months, Tim is looking to significantly grow
            the engineering organization. While he knows that such rapid growth
            comes with its own challenges, he’s also committed to scaling the
            engineering organization sustainably by understanding the impact of
            each new hire.
          </p>
          <QuoteBlock
            quote="At our stage, growing engineering is never a nice steady march. One of the things I’m most excited about is using Swarmia to see what the addition of a body to a team does to our stats. It’s great for us to hire an experienced developer but it’s even greater to understand the impact that has on delivery. I know we’re going to be in good shape if we use Swarmia to support our growth."
            name="Tim Nott"
            title="CTO"
            photo={tim}
          />
        </>
      }
    />
  )
}
export default FactoryFixBody
